export const FETCH_BLOCKS = 'FETCH_BLOCKS';
export const FETCH_BLOCKS_SUCCESS = 'FETCH_BLOCKS_SUCCESS';
export const FETCH_BLOCKS_ERROR = 'FETCH_BLOCKS_ERROR';
export const FETCH_NEW_BLOCKS = 'FETCH_NEW_BLOCKS';
export const FETCH_NEW_BLOCKS_SUCCESS = 'FETCH_NEW_BLOCKS_SUCCESS';
export const CLEAR_FEED_BLOCKS = 'CLEAR_FEED_BLOCKS';
export const FETCH_BLOCK_SUCCESS = 'FETCH_BLOCK_SUCCESS';
export const FETCH_TRANSACTIONS = 'FETCH_TRANSACTIONS';
export const FETCH_TRANSACTIONS_SUCCESS = 'FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTION_SUCCESS = 'FETCH_TRANSACTION_SUCCESS';
export const FETCH_BLOCK_CHAIN_INFO_SUCCESS = 'FETCH_BLOCK_CHAIN_INFO_SUCCESS';
export const SET_FILTERS = 'SET_FILTERS';
export const FETCH_ACCOUNTS = 'FETCH_ACCOUNTS';
export const FETCH_ACCOUNTS_SUCCESS = 'FETCH_ACCOUNTS_SUCCESS';
export const FETCH_ACCOUNTS_ERROR = 'FETCH_ACCOUNTS_ERROR';
export const FETCH_ACCOUNT = 'FETCH_ACCOUNT';
export const FETCH_ACCOUNT_SUCCESS = 'FETCH_ACCOUNT_SUCCESS';
export const FETCH_ACCOUNT_ERROR = 'FETCH_ACCOUNT_ERROR';
export const FETCH_ACCOUNT_TRANSACTIONS = 'FETCH_ACCOUNT_TRANSACTIONS';
export const FETCH_ACCOUNT_TRANSACTIONS_SUCCESS = 'FETCH_ACCOUNT_TRANSACTIONS_SUCCESS';
export const CHANGE_GRANT_STATE = 'CHANGE_GRANT_STATE';
export const FETCH_TOKEN_BALANCES = 'FETCH_TOKEN_BALANCES';
export const FETCH_TOKEN_BALANCES_SUCCESS = 'FETCH_TOKEN_BALANCES_SUCCESS';
export const FETCH_BIDS_SUCCESS = 'FETCH_BIDS_SUCCESS';
export const FETCH_LAST_CLOSED_BID_SUCCESS = 'FETCH_LAST_CLOSED_BID_SUCCESS';
