import React, { PureComponent, createRef, RefObject } from 'react';
import styled from 'styled-components';

import { AccountLine, Suggest } from '../../types';
import Link from '../Link';

const SYMBOLS_BY_TYPE = {
  block: 'Block',
  transaction: 'Tx',
  account: 'Acc',
};

const Wrapper = styled.div`
  position: absolute;
  top: 32px;
  left: 0;
  width: 500px;
  padding: 3px 0;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 1;
`;

const NoResults = styled.div`
  padding: 8px;
`;

const List = styled.ul``;

const Item = styled.li``;

const LinkStyled = styled(Link)`
  display: block;
  padding: 8px;
  color: unset;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    color: #fff;
    background-color: #1890ff;
  }
`;

type Props = {
  items: Suggest[];
  close: Function;
};

export default class SuggestPanel extends PureComponent<Props> {
  rootRef: RefObject<HTMLDivElement> = createRef();

  private addListenerTimeout: number | undefined = undefined;

  componentDidMount() {
    this.addListenerTimeout = setTimeout(() => {
      document.addEventListener('click', this.onAwayClick);
    }, 200);
  }

  componentWillUnmount() {
    clearTimeout(this.addListenerTimeout);
    document.removeEventListener('click', this.onAwayClick);
  }

  onAwayClick = (e: MouseEvent) => {
    if (this.rootRef.current && !this.rootRef.current.contains(e.target as Node)) {
      this.props.close();
    }
  };

  onItemClick = () => {
    this.props.close();
  };

  getRoute({ type, data }: Suggest) {
    switch (type) {
      case 'block':
        return `/block/${data.id}`;
      case 'transaction':
        return `/trx/${data.id}`;
      case 'account':
        return `/account/${data.id}`;
      default:
        throw new Error('Unknown type');
    }
  }

  renderItem = (item: Suggest, i: number) => {
    let text = item.data.id;

    if (item.type === 'account') {
      const account = item.data as AccountLine;

      if (account.golosId) {
        text += ` (${account.golosId})`;
      }
    }

    return (
      <Item key={i}>
        <LinkStyled to={this.getRoute(item)} onClick={this.onItemClick}>
          {SYMBOLS_BY_TYPE[item.type]}: {text}
        </LinkStyled>
      </Item>
    );
  };

  render() {
    const { items } = this.props;

    return (
      <Wrapper ref={this.rootRef}>
        {items.length ? (
          <List>{items.map(this.renderItem)}</List>
        ) : (
          <NoResults>Nothing is found</NoResults>
        )}
      </Wrapper>
    );
  }
}
