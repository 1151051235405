import { combineReducers } from 'redux';

import blocks, { State as BlocksState } from './blocks';
import blockIdByNum, { State as BlockIdByNumState } from './blockIdByNum';
import blocksFeed, { State as BlocksFeedState } from './blocksFeed';
import transactions, { State as TransactionsState } from './transactions';
import blockTransactions, { State as BlockTransactionsState } from './blockTransactions';
import blockchain, { State as BlockchainState } from './blockchain';
import filters, { State as FiltersState } from './filters';
import currentAccount, { State as CurrentAccountState } from './currentAccount';
import accountTransactions, { State as AccountTransactionsState } from './accountTransactions';
import tokenHolders, { State as TokenHoldersState } from './tokenHolders';
import auctions, { State as AuctionsState } from './auctions';

export type State = {
  blocks: BlocksState;
  blockIdByNum: BlockIdByNumState;
  blocksFeed: BlocksFeedState;
  transactions: TransactionsState;
  blockTransactions: BlockTransactionsState;
  blockchain: BlockchainState;
  filters: FiltersState;
  currentAccount: CurrentAccountState;
  accountTransactions: AccountTransactionsState;
  tokenHolders: TokenHoldersState;
  auctions: AuctionsState;
};

export default combineReducers({
  blocks,
  blockIdByNum,
  blocksFeed,
  transactions,
  blockTransactions,
  blockchain,
  filters,
  currentAccount,
  accountTransactions,
  tokenHolders,
  auctions,
});
